<template>
    <div id="list">
        <div class="handle">
            <span :class="{'active' : curType == 1}" @click="curType = 1">已预约</span>
            <span :class="{'active' : curType == 2}" @click="curType = 2">已完成</span>
            <span :class="{'active' : curType == 3}" @click="curType = 3">已取消</span>
        </div>
        <div class="content">
            <div v-show="curType == 1">
                <el-card class="card-wrap" v-for="(item, index) in yuYueList" :key="index">
                    <div class="text-wrap">
                        <div class="text-item">姓名：{{item.full_name}}  {{item.id_number}}</div>
                        <div class="text-item">电话：{{item.mobile}}</div>
                        <div class="text-item">类型：{{item.ex_title}}</div>
                        <div class="text-item">单号：{{item.order_no}}</div>
                        <div class="text-item">下单时间：{{item.created_at}}</div>
                    </div>
                    <el-button type="primary" size="mini" class="close-btn" @click="changeClose(item)">取消预约</el-button>
                    <el-button type="primary" size="mini" class="see-btn" @click="showImg(item)">查看核销码</el-button>
                </el-card>
                <div class="no-data" v-show="!yuYueList.length">
                    暂无订单～
                </div>
            </div>
            <div v-show="curType == 2">
                <el-card class="card-wrap" v-for="(item, index) in writeList" :key="index">
                    <div class="text-wrap">
                        <div class="text-item">姓名：{{item.full_name}}  {{item.id_number}}</div>
                        <div class="text-item">电话：{{item.mobile}}</div>
                        <div class="text-item">类型：{{item.ex_title}}</div>
                        <div class="text-item">单号：{{item.order_no}}</div>
                        <div class="text-item">标本号：{{item.sample}}</div>
                        <div class="text-item">下单时间：{{item.created_at}}</div>
                        <div class="text-item">核销时间：{{item.checked_time}}</div>
                    </div>
                </el-card>
                <div class="no-data" v-show="!writeList.length">
                    暂无订单～
                </div>
            </div>
            <div v-show="curType == 3">
                <el-card class="card-wrap" v-for="(item, index) in refundList" :key="index">
                    <div class="text-wrap">
                        <div class="text-item">姓名：{{item.full_name}}  {{item.id_number}}</div>
                        <div class="text-item">电话：{{item.mobile}}</div>
                        <div class="text-item">类型：{{item.ex_title}}</div>
                        <div class="text-item">单号：{{item.order_no}}</div>
                        <div class="text-item">下单时间：{{item.created_at}}</div>
                        <div class="text-item">取消时间：{{item.refund_time}}</div>
                    </div>
                </el-card>
                <div class="no-data" v-show="!refundList.length">
                    暂无订单～
                </div>
            </div>
        </div>
        <el-dialog width="90%" :visible.sync="show_img">
            <div>
                <img :src="code_url">
            </div>
            <div slot="footer"></div>
        </el-dialog>
        <el-dialog
            :visible.sync="show_close"
            width="90%">
            <span>确定取消当前预约吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="show_close = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="confirmClose()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "list",
    data() {
        return {
            curType: 1,
            curItem: null,
            yuYueList: [],
            writeList: [],
            refundList: [],
            code_url: null,
            show_img: false,
            show_close: false,
            timer: null
        };
    },
    methods: {
        get_list() {
            this.$post('api/order/yuYueList')
                .then(res => {
                    this.yuYueList = res.datas;
                });
        },
        getList() {
            this.$post('api/order/writeOf')
                .then(res => {
                    this.writeList = res.datas;
                });
            this.$post('api/order/refundList')
                .then(res => {
                    this.refundList = res.datas;
                });
        },
        changeClose(item) {
            this.curItem = item;
            this.show_close = true;
        },
        confirmClose() {
            this.$post('api/order/ordRefund', {
                order_no: this.curItem.order_no
            })
            .then(res => {
                this.get_list();
                this.show_close = false;
                this.$message.success(res.message);
                this.yuYueList.splice(this.yuYueList.indexOf(this.curItem), 1);
            });
        },
        showImg(item) {
            this.code_url = this.$common.BASE_HOST + item.code_url;
            this.show_img = true;
            this.orderState(item);
        },
        orderState(order) {
            let _that = this;
            this.timer = setInterval(() => {
                _that.$post('api/order/verifyState', {
                    order_no: order.order_no
                })
                .then(res => {
                    if(res.datas.state == 1) {
                        _that.get_list();
                        _that.show_close = false;
                        clearInterval(_that.timer);
                        _that.$message.success(res.datas.state_txt);
                    }
                });
            }, 3000);
        }
    },
    mounted() {
        this.getList();
        this.get_list();
    }
};
</script>

<style scoped>
    #list {
        min-height: 100vh;
        background-color: #F4F6F8;
    }
    #list .handle {
        color: #7785A1;
        background-color: white;
        padding: 20px 0 10px;
        margin-bottom: 8px;
    }
    #list .handle span {
        margin: 0 20px;
        padding: 0 5px;
    }
    #list .active {
        color: #122F56;
        position: relative;
    }
    #list .active::after {
        display: block;
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: -10px;
        background-color: #007EFF;
    }
    #list .content .no-data {
        text-align: center;
        line-height: 460px;
    }
    #list .card-wrap {
        position: relative;
        margin-bottom: 5px;
    }
    #list .card-wrap .text-item {
        font-size: 14px;
        margin-bottom: 18px;
    }
    #list .card-wrap .text-item:last-child {
        margin: 0;
    }
    #list .card-wrap .close-btn,
    #list .card-wrap .see-btn {
        position: absolute;
        right: 20px;
    }
    #list .card-wrap .close-btn {
        top: 20px;
    }
    #list .card-wrap .see-btn {
        bottom: 20px;
    }
    #list >>> .el-button--primary {
        background-color: #007FFF;
        box-shadow: 2px 2px 10px -3px #007FFF;
    }
    #list >>> .el-dialog .el-dialog__body {
        padding: 30px 15px;
    }
    #list >>> .el-dialog .el-dialog__body img {
        width: 100%;
    }
</style>